import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { baseUrl } from "../../../apiCalls/config";

const PendingMedicPayments = () => {
  const [medics, setMedics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMedic, setSelectedMedic] = useState(null);
  const [amountNeeded, setAmountNeeded] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [noPendingPayments, setNoPendingPayments] = useState(false); 
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchPendingPayments = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${baseUrl}/api/admin/need-payment`,
        config
      );

      if (
        response.data.success === false &&
        response.data.message === "No medics found who need payment"
      ) {
        setNoPendingPayments(true); 
        setLoading(false);
        return;
      }

      const data = response.data.data.map((item) => ({
        id: item.id,
        name: `${item.bioData.firstName} ${item.bioData.lastName}`,
        appointmentId: item.appIdForPayment,
        amountNeeded: item.amountNeeded,
        email: item.bioData.email,
        phone: item.bioData.phoneNumber,
        appointmentStartDate: item.appointmentStartDate,
        appointmentEndDate: item.appointmentEndDate,
        pvbWalletId: item.pvbWalletId,
        details: item,
      }));

      setMedics(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pending payments:", error);
      setLoading(false);
      toast({
        title: "Error",
        description: "Failed to load pending payments.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    }
  }, [toast]);

  useEffect(() => {
    fetchPendingPayments();
  }, [fetchPendingPayments]);

  const handleRowClick = (medic) => {
    setSelectedMedic(medic);
    setAmountNeeded(medic.amountNeeded);
    onOpen();
  };

  const calculateDurationInDays = (startDate) => {
    if (!startDate) return null;
    const start = new Date(startDate);
    const end = new Date(); // Current date
    const durationInMs = end - start;
    const durationInDays = durationInMs / (1000 * 60 * 60 * 24);
    return Math.round(durationInDays);
  };
  

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Intl.DateTimeFormat("en-US", options).format(
      new Date(dateString)
    );
  };

  const handleApprovePayment = async () => {
    if (!selectedMedic) return;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    setIsApproving(true);

    try {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      };

      const formattedAmount = Number(amountNeeded.replace(/,/g, ''));

      const params = new URLSearchParams();
      params.append("appointmentId", selectedMedic.id);
      params.append("amount", formattedAmount);
      params.append("destination", selectedMedic.pvbWalletId);

      const response = await axios.post(
        `${baseUrl}/api/wallets/approve-medic-payment`,
        params,
        config
      );

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "Payment approved successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
        fetchPendingPayments();
      } else {
        const errorMessage =
          response.data.message ||
          "Failed to approve payment. Please try again.";
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-left",
        });
      }
    } catch (error) {
      console.error("Error approving payment:", error);

      const errorMessage =
        error.response?.data?.message ||
        "Failed to approve payment. Please try again.";
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    } finally {
      setIsApproving(false);
    }
  };


  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatAmount1 = (value) => {
    const cleanedValue = value.replace(/[^0-9.]/g, "");
    const formattedValue = Number(cleanedValue).toLocaleString();
    return formattedValue;
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatAmount1(inputValue);
    setAmountNeeded(formattedValue);
  };
  
  return (
    <Box
      overflowY="auto"
      maxH="600px"
      bg="#4B4B4B"
      borderRadius="10px"
      p={4}
      color="white"
      w="100%"
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Pending Medic Payments
        </Text>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <ClipLoader color="#00C6F7" />
        </Flex>
      ) : noPendingPayments ? ( // Check if there are no pending payments
        <Flex justifyContent="center" alignItems="center">
          <Text>No pending medic payment.</Text>
        </Flex>
      ) : (
        <Box maxH="400px" overflowY="auto">
          <Table variant="simple" colorScheme="whiteAlpha">
            <Thead
              css={{
                position: "sticky",
                top: 0,
                background: "#4B4B4B",
                zIndex: 1,
              }}
            >
              <Tr>
                <Th color="purple.200">Medic Name</Th>
                <Th color="purple.200">Appointment ID</Th>
                <Th color="purple.200">Amount Needed</Th>
                <Th color="purple.200">Email Address</Th>
                <Th color="purple.200">Phone Number</Th>
              </Tr>
            </Thead>
            <Tbody>
              {medics.map((medic, index) => (
                <Tr
                  key={index}
                  fontSize="13px"
                  _hover={{ bg: "#3B3B3B" }}
                  onClick={() => handleRowClick(medic)}
                  cursor="pointer"
                >
                  <Td>{medic.name}</Td>
                  <Td>{medic.appointmentId}</Td>
                  <Td>₦{formatAmount(medic.amountNeeded)}</Td>
                  <Td>{medic.email}</Td>
                  <Td>{medic.phone}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {selectedMedic && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bg="#4B4B4B" color="white">
            <ModalHeader>Approve Payment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={4} color="yellow.500">
                Please confirm the appointment schedule and ensure the amount is
                correct before approving the payment.
              </Text>
              <Text mb={4}>
                <strong>Appointment ID:</strong> {selectedMedic.appointmentId}
              </Text>
              <Text mb={4}>
                <strong>Medic Start Date:</strong>{" "}
                {formatDate(selectedMedic.appointmentStartDate)}
              </Text>
              <Text mb={4}>
                <strong>Medic End Date:</strong>{" "}
                {formatDate(selectedMedic.appointmentEndDate)}
              </Text>
              <Text mb={4}>
                <strong>Duration:</strong>{" "}
                {calculateDurationInDays(
                  selectedMedic.appointmentStartDate
                )}{" "}
                days
              </Text>
              <Text mb={4}>
                <strong>Amount Needed:</strong>
              </Text>
              <Input
                value={amountNeeded}
                onChange={handleAmountChange}
                placeholder="Enter Amount Needed"
              />
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="red"
                mr={3}
                onClick={onClose}
                disabled={isApproving}
              >
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={handleApprovePayment}
                disabled={isApproving}
              >
                {isApproving ? (
                  <>
                    <ClipLoader size={20} color="white" />
                    <Text ml={2}>Approving...</Text>
                  </>
                ) : (
                  "Approve"
                )}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default PendingMedicPayments;
