import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Avatar,
  Text,
  VStack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { baseUrl } from "../../../apiCalls/config";

const PendingMedicAppointmentDetailsDrawer = ({
  isOpen,
  onClose,
  appointment,
}) => {
  const { isOpen: isEditModalOpen, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();
  const { isOpen: isCancelModalOpen, onOpen: openCancelModal, onClose: closeCancelModal } = useDisclosure();

  const [anyModalOpen, setAnyModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    medicId: appointment.medicAppointment.medicId,
    appointmentId: appointment.id,
    currentLocation: appointment.medicAppointment.currentLocation,
    appointmentType: appointment.medicAppointment.appointmentType,
    specialization: appointment.medicAppointment.specialization,
    shift: appointment.medicAppointment.shift,
    preferredTown: appointment.medicAppointment.preferredTown,
  });
  const toast = useToast();

  const handleClose = () => {
    if (!anyModalOpen) {
      setFormData({}); // Only reset form data if no modals are open
    }
    onClose(); // Call the passed onClose prop to handle the drawer close
  };

  useEffect(() => {
    if (appointment && isOpen) {
      setFormData({
        medicId: appointment.medicAppointment.medicId,
        appointmentId: appointment.id,
        currentLocation: appointment.medicAppointment.currentLocation,
        appointmentType: appointment.medicAppointment.appointmentType,
        specialization: appointment.medicAppointment.specialization,
        shift: appointment.medicAppointment.shift,
        preferredTown: appointment.medicAppointment.preferredTown,
      });
    }
  }, [appointment, isOpen]);

  useEffect(() => {
    setAnyModalOpen(isEditModalOpen || isCancelModalOpen);
  }, [isEditModalOpen, isCancelModalOpen]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    try {
      const response = await fetch(
        `${baseUrl}/api/admin/edit-request/${formData.appointmentId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
  
      if (response.ok) {
        toast({
          title: "Request Updated",
          description: "The request details have been successfully updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        closeEditModal();
      } else {
        throw new Error("Failed to update request");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    }
  };
  

  const handleCancelRequest = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    try {
      const response = await fetch(
        `${baseUrl}/api/admin/cancel-request/${formData.appointmentId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${token}`,
          },
          body: new URLSearchParams({ appointmentId: formData.appointmentId }),
        }
      );
  
      if (response.ok) {
        toast({
          title: "Request Cancelled",
          description: "The request has been successfully cancelled.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        closeCancelModal();
        onClose(); 
      } else {
        throw new Error("Failed to cancel request");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    }
  };
  
  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="md">
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader>Pending Medic Appointment Details</DrawerHeader>
          <DrawerBody>
            <VStack align="left" spacing={4}>
              <Flex justifyContent="center">
                <Avatar
                  size="xl"
                  name={appointment.medicAppointment.fullName}
                  src={appointment.medicAppointment?.image}
                  alt={appointment.medicAppointment.fullName}
                />
              </Flex>
              <Text fontSize="2xl" fontWeight="bold">
                {appointment.medicAppointment.fullName}
              </Text>
              <Text>Specialization: {appointment.medicAppointment.specialization}</Text>
              <Text>Shift: {appointment.medicAppointment.shift}</Text>
              <Text>Years of Experience: {appointment.medicAppointment.yearsOfExp}</Text>
              <Text>Rating: {appointment.medicAppointment.rating}</Text>
              <Text>Current Location: {appointment.medicAppointment.currentLocation || "N/A"}</Text>
              <Text>Preferred Town: {appointment.medicAppointment.preferredTown || "N/A"}</Text>
              <Text>Appointment Type: {appointment.medicAppointment.appointmentType}</Text>
              <Text>
                Created At:{" "}
                {new Date(appointment.medicAppointment.createdAt).toLocaleDateString()}
              </Text>
              <Text>
                Updated At:{" "}
                {appointment.medicAppointment.updatedAt
                  ? new Date(appointment.medicAppointment.updatedAt).toLocaleDateString()
                  : "N/A"}
              </Text>
              
            </VStack>
          </DrawerBody>
          <DrawerFooter justifyContent="space-between">
            <Button variant="outline" colorScheme="red" onClick={openCancelModal}>
              Cancel Request
            </Button>
            <Button variant="outline" colorScheme="blue" onClick={openEditModal}>
              Edit Request
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Edit Modal */}
      <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Edit Medic Appointment Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Current Location</FormLabel>
                <Input
                  name="currentLocation"
                  value={formData.currentLocation}
                  onChange={handleInputChange}
                  placeholder="Enter current location"
                  bg="white"
                  color="black"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Appointment Type</FormLabel>
                <Input
                  name="appointmentType"
                  value={formData.appointmentType}
                  onChange={handleInputChange}
                  placeholder="Enter appointment type"
                  bg="white"
                  color="black"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Specialization</FormLabel>
                <Input
                  name="specialization"
                  value={formData.specialization}
                  onChange={handleInputChange}
                  placeholder="Enter specialization"
                  bg="white"
                  color="black"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Shift</FormLabel>
                <Input
                  name="shift"
                  value={formData.shift}
                  onChange={handleInputChange}
                  placeholder="Enter shift"
                  bg="white"
                  color="black"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Preferred Town</FormLabel>
                <Input
                  name="preferredTown"
                  value={formData.preferredTown}
                  onChange={handleInputChange}
                  placeholder="Enter preferred town"
                  bg="white"
                  color="black"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline"  colorScheme="blue" mr={3} onClick={handleFormSubmit}>
              Save Changes
            </Button>
            <Button variant="outline" colorScheme="white" onClick={closeEditModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Cancel Confirmation Modal */}
      <Modal isOpen={isCancelModalOpen} onClose={closeCancelModal}>
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Confirm Cancellation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to cancel this request?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" variant="outline"  mr={3} onClick={handleCancelRequest}>
              Confirm
            </Button>
            <Button variant="outline" colorScheme="white" onClick={closeCancelModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PendingMedicAppointmentDetailsDrawer;
