import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  Checkbox,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";

const EditAppointmentModal = ({ isOpen, onClose, appointment }) => {
  const [formData, setFormData] = useState({ ...appointment });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/update-appointment`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: "Appointment Updated",
          description:
            "The appointment was updated successfully. Kindly refresh to see the update.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      } else {
        toast({
          title: "Update Failed",
          description: "There was an issue updating the appointment.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while updating the appointment.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const majorReligions = ["Christianity", "Islam", "Traditional", "Anyone"];

  return (
    <Modal style={{ zIndex: 1400 }} size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxH="550px" overflow="auto" bg="#4B4B4B" color="white">
        <ModalHeader>Edit Appointment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3} id="recipientFirstname">
            <FormLabel>Recipient First Name</FormLabel>
            <Input
              type="text"
              name="recipientFirstname"
              value={formData.recipientFirstname || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="recipientLastname">
            <FormLabel>Recipient Last Name</FormLabel>
            <Input
              type="text"
              name="recipientLastname"
              value={formData.recipientLastname || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="recipientPhoneNumber">
            <FormLabel>Recipient Phone Number</FormLabel>
            <Input
              type="text"
              name="recipientPhoneNumber"
              value={formData.recipientPhoneNumber || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="recipientGender">
            <FormLabel>Recipient Gender</FormLabel>
            <Select
              color="black"
              name="recipientGender"
              value={formData.recipientGender || ""}
              onChange={handleInputChange}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Select>
          </FormControl>
          <FormControl mb={3} id="medicSpecialization">
            <FormLabel>Medic Type</FormLabel>
            <Select
              color="black"
              name="medicSpecialization"
              placeholder="Medic Type"
              value={formData.medicSpecialization || ""}
              onChange={handleInputChange}
            >
              <option value="Registered Nurse">Registered Nurse</option>
              <option value="Certified Nurse Assistant">
                Certified Nurse Assistant
              </option>
              <option value="Professional Nanny">Professional Nanny</option>
            </Select>
          </FormControl>
          <FormControl mb={3} id="needMedicalReport">
            <FormLabel>Need Medical Report</FormLabel>
            <Checkbox
              name="needMedicalReport"
              isChecked={formData.needMedicalReport}
              onChange={handleCheckboxChange}
            >
              {formData.needMedicalReport ? "Yes" : "No"}
            </Checkbox>
          </FormControl>
          <FormControl mb={3} id="isPaid">
            <FormLabel>Paid</FormLabel>
            <Checkbox
              name="paid"
              isChecked={formData.paid}
              onChange={handleCheckboxChange}
            >
              {formData.paid ? "Yes" : "No"}
            </Checkbox>
          </FormControl>
          <FormControl mb={3} id="isRefunded">
            <FormLabel>Refunded</FormLabel>
            <Checkbox
              name="refunded"
              isChecked={formData.refunded}
              onChange={handleCheckboxChange}
            >
              {formData.refunded ? "Yes" : "No"}
            </Checkbox>
          </FormControl>
          <FormControl mb={3} id="isMedicPaid">
            <FormLabel>Medic Paid</FormLabel>
            <Checkbox
              name="medicPaid"
              isChecked={formData.medicPaid}
              onChange={handleCheckboxChange}
            >
              {formData.medicPaid ? "Yes" : "No"}
            </Checkbox>
          </FormControl>
          <FormControl mb={3} id="recipientDOB">
            <FormLabel>Recipient Date of Birth</FormLabel>
            <Input
              type="date"
              name="recipientDOB"
              value={formData.recipientDOB || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="currentLocation">
            <FormLabel>Current Location</FormLabel>
            <Input
              type="text"
              name="currentLocation"
              value={formData.currentLocation || ""}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl mb={3} id="preferredReligion">
            <FormLabel>Preferred Religion</FormLabel>
            <Select
              isRequired
              borderRadius="5px"
              w="full"
              color="black"
              fontSize={{ base: "14px", md: "16px" }}
              name="religion"
              placeholder="Select Religion"
              value={formData?.preferredReligion}
              onChange={handleInputChange}
            >
              {majorReligions.map((religion) => (
                <option key={religion} value={religion}>
                  {religion}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl mb={3} id="costOfService">
            <FormLabel>Cost of Service</FormLabel>
            <Input
              type="number"
              name="costOfService"
              value={formData.costOfService || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="amountPayable">
            <FormLabel>Amount Payable</FormLabel>
            <Input
              type="number"
              name="amountPayable"
              value={formData.amountPayable || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="startDate">
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              name="startDate"
              value={formData.startDate || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="endDate">
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              name="endDate"
              value={formData.endDate || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="actualStartDate">
            <FormLabel>Actual Start Date</FormLabel>
            <Input
              type="date"
              name="actualStartDate"
              value={formData.actualStartDate || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="actualEndDate">
            <FormLabel>Actual End Date</FormLabel>
            <Input
              type="date"
              name="actualEndDate"
              value={formData.actualEndDate || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3} id="duration">
            <FormLabel>Duration</FormLabel>
            <Input
              type="number"
              name="duration"
              value={formData.duration || ""}
              onChange={handleInputChange}
            />
          </FormControl>
          {/* <FormControl mb={3} id="recipientHealthHistory">
            <FormLabel>HX</FormLabel>
            <Input
              type="text"
              name="recipientHealthHistory"
              value={formData.recipientHealthHistory || ""}
              onChange={handleInputChange}
            />
          </FormControl> */}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSave} disabled={loading}>
            {loading ? <Spinner size="sm" /> : "Save"}
            {loading && " Saving..."}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditAppointmentModal;
