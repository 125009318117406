import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { loginBaseUrl } from "../../apiCalls/config";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Button,
  Image,
  HStack,
  extendTheme,
  ChakraProvider,
  Text,
  PinInput,
  PinInputField,
  VStack,
} from "@chakra-ui/react";
import logo from "../../assets/Logo.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
  fonts: {
    body: "Gill Sans MT, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const VerifyMedicNumber = () => {
  const navigate = useNavigate();
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const handleComplete = (value) => {
    setOtp(value);
  };

  const resendOtp = async () => {
    setResendLoading(true);
    try {
      const number = localStorage.getItem("phoneNumber");
      const response = await axios.post(
        `${loginBaseUrl}/api/v1/sms/verify-number`,
        { phoneNumber: number },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log(response);
      toast.success("A new OTP has been sent to your phone.");
    } catch (error) {
      toast.error("Unable to resend OTP at this time. Please try again later.");
    } finally {
      setResendLoading(false);
    }
  };

  const handleVerify = async () => {
    if (!otp || otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP.");
      return;
    }

    setVerifyLoading(true);
    try {
      const number = localStorage.getItem("phoneNumber");
      const response = await axios.post(
        `${loginBaseUrl}/api/v1/sms/verify-otp`,
        { phoneNumber: number, otpNumber: otp },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.success) {
        toast.success("OTP Verification Successful");
        setTimeout(() => {
          navigate("/complete");
        }, 5000);
      } else {
        console.error("Error verifying otp");
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error verifying otp:", error);
      toast.error(
        "Wrong or expired OTP, confirm the code sent or click 'resend code' for a new code"
      );
    } finally {
      setVerifyLoading(false);
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ChakraProvider theme={customTheme}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        p={{ base: 4, md: 8 }}
        bg="white"
      >
        <VStack
          spacing={4}
          align="center"
          justify="center"
          w={{ base: "100%", sm: "400px", md: "500px" }}
          bg="white"
          boxShadow="lg"
          p={4}
          borderRadius="md"
        >
          <a href="/">
            <Image
              justifySelf="center"
              src={logo}
              alt="Logo"
              h={{ base: "80px", md: "100px" }}
              w={{ base: "300px", md: "350px" }}
            />
          </a>
          <Text fontSize={{ base: "xl", md: "2xl" }} color="#A210C6">
            Verify your phone number
          </Text>
          <Text fontSize={{ base: "md", md: "lg" }} color="gray.600" textAlign="center">
            Please input the 6-digit code sent to your phone number
          </Text>
          <HStack>
            <PinInput onComplete={handleComplete}>
              {Array.from({ length: 6 }, (_, index) => (
                <PinInputField key={index} />
              ))}
            </PinInput>
          </HStack>
          <Text fontSize="lg" fontFamily="Montserrat" mt={4} textAlign="center">
            Didn’t receive a code?{" "}
            <Button
              variant="link"
              color="#A210C6"
              onClick={resendOtp}
              isLoading={resendLoading}
            >
              Resend code
            </Button>
          </Text>
          <Button
            w="200px"
            bg="linear-gradient(80deg, #A210C6, #E552FF)"
            onClick={handleVerify}
            isLoading={verifyLoading}
            loadingText="Verifying..."
            color="white"
          >
            Verify
          </Button>
        </VStack>
      </Box>
    </ChakraProvider>
  );
};

export default VerifyMedicNumber;
