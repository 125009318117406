import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
} from "@chakra-ui/react";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import PendingMedicAppointmentDetailsDrawer from "../../sections/admin/PendingMedicAppointmentDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const ActiveMedics = () => {
  const [medicsData, setMedicsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchMedics = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${baseUrl}/api/admin/pending-medic-appointments`,
          config
        );
        setMedicsData(response.data.data.content);
      } catch (error) {
        console.error("Error fetching medics:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMedics();
  }, []);

  const handleRowClick = (appointment) => {
    setSelectedAppointment(appointment);
    setIsDrawerOpen(true);
  };

  return (
    <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="md" fontWeight="bold">
          Pending Medic Requests
        </Text>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" h="200px">
          <Spinner size="xl" />
        </Flex>
      ) : medicsData.length > 0 ? (
        <Table variant="simple" colorScheme="whiteAlpha">
          <Thead
            css={{
              position: "sticky",
              top: 0,
              background: "#4B4B4B",
              zIndex: 1,
            }}
          >
            <Tr>
              <Th color="purple.200">Avatar</Th>
              <Th color="purple.200">Medic Full Name</Th>
              <Th color="purple.200">Service Plan</Th>
              <Th color="purple.200">Current Location</Th>
              <Th color="purple.200">Timestamp</Th>
            </Tr>
          </Thead>
          <Tbody>
            {medicsData.map((medic) => (
              <Tr
                key={medic.id}
                onClick={() => handleRowClick(medic)}
                cursor="pointer"
                fontSize="14px"
                _hover={{ bg: "#3B3B3B" }}
              >
                <Td>
                  <Avatar
                    src={
                      medic.medicAppointment.image ||
                      "https://bit.ly/dan-abramov"
                    }
                    size="md"
                  />
                </Td>
                <Td>{medic.medicAppointment.fullName}</Td>
                <Td>{medic.medicAppointment.specialization}</Td>
                <Td maxW="350px">
                  {medic.medicAppointment.currentLocation || "N/A"}
                </Td>
                <Td>
                  {formatDistanceToNow(
                    new Date(medic.medicAppointment.createdAt),
                    {
                      addSuffix: true,
                    }
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Text>No available medics at the moment</Text>
      )}

      {selectedAppointment && (
        <PendingMedicAppointmentDetailsDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          appointment={selectedAppointment}
        />
      )}
    </Box>
  );
};

export default ActiveMedics;
