import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import axios from "axios";
import AppointmentDetailsDrawer from "../../sections/admin/AppointmentDetailsDrawer";
import { baseUrl } from "../../../apiCalls/config";

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const ActiveAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClose = () => {
    setSelectedAppointment(null); 
    setIsDrawerOpen(false)  
  };


  useEffect(() => {
    const fetchAppointments = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${baseUrl}/api/admin/active-appointments`,
          config
        );
        setAppointments(response.data.data);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, []);
  const handleRowClick = (appointment) => {
    setSelectedAppointment(appointment);
    setIsDrawerOpen(true);
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A";
    const options = {
      year: 'numeric', month: 'long', day: 'numeric',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: true,
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };
  
  return (
    <Box
      maxH="480px"
      h="500px"
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": { display: "none" },
        "-ms-overflow-style": "none" /* IE and Edge */,
        "scrollbar-width": "none" /* Firefox */,
      }}
    >
      <Box bg="#4B4B4B" borderRadius="10px" p={4} color="white" w="650px">
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Text fontSize="lg" fontWeight="bold">
            Active Appointments
          </Text>
        </Flex>
        {loading ? (
          <Flex justifyContent="center" alignItems="center" h="200px">
            <Spinner size="xl" />
          </Flex>
        ) : appointments?.length > 0 ? (
          <Box  maxH="480px" w="650px">
            <Table variant="simple" colorScheme="whiteAlpha">
              <Thead
                css={{
                  position: "sticky",
                  top: 0,
                  background: "#4B4B4B",
                  zIndex: 1,
                }}
              >
                <Tr>
                  <Th color="purple.200">Recipient Name</Th>
                  <Th color="purple.200">Medic Name</Th>
                  <Th color="purple.200">Start Date</Th>
                  <Th color="purple.200">End Date</Th>
                  <Th color="purple.200">Last Report Sent</Th>
                </Tr>
              </Thead>
              <Tbody>
                {appointments?.map((appointment) => (
                  <Tr
                    key={appointment.id}
                    onClick={() => handleRowClick(appointment)}
                    cursor="pointer"
                    fontSize="12px"
                    _hover={{ bg: "#3B3B3B" }}
                  >
                    <Td>
                      {appointment.recipientFirstname}{" "}
                      {appointment.recipientLastname}
                    </Td>
                    <Td>
                      {" "}
                      {appointment.matchedMedic.bioData.firstName}{" "}
                      {appointment.matchedMedic.bioData.lastName}{" "}
                    </Td>
                    <Td>{formatDate(appointment.actualStartDate)}</Td>
                    <Td>{formatDate(appointment.actualEndDate)}</Td>
                    <Td>{formatDateTime(appointment.lastReportSent)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ) : (
          <Text>No active appointments at the moment</Text>
        )}
      </Box>

      {selectedAppointment && (
        <AppointmentDetailsDrawer
          isOpen={isDrawerOpen}
          onClose={handleClose}
          appointment={selectedAppointment}
        />
      )}
    </Box>
  );
};

export default ActiveAppointments;
