import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";

const EditCustomerModal = ({ isOpen, onClose, customer }) => {
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    gender: "",
    dob: "",
    image: "",
    kinName: "",
    kinNumber: "",
    language: "",
    relationship: "",
    address: "",
  });
  const [loading, setLoading] = useState(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const toast = useToast();

  useEffect(() => {
    if (customer) {
      setFormData({
        id: customer.id || "",
        firstName: customer.bioData?.firstName || "",
        lastName: customer.bioData?.lastName || "",
        phoneNumber: customer.bioData?.phoneNumber || "",
        email: customer.bioData?.email || "",
        gender: customer.bioData?.gender || "",
        dob: customer.bioData?.dob ? customer.bioData.dob.split("T")[0] : "",
        image: customer.bioData?.image || "",
        kinName: customer.bioData?.kinName || "",
        kinNumber: customer.bioData?.kinNumber || "",
        language: customer.bioData?.language || "",
        relationship: customer.bioData?.relationship || "",
        address: customer.bioData?.address || "",
        startDate: customer?.appointmentStartDate || "",
        endDate: customer?.appointmentEndDate || "",
      });
    }
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = async (image) => {
    setImageLoading(true);

    if (image === undefined) {
      // toast.error("Please select an image")
      setImageLoading(false);
      return;
    }

    if (image.type === "image/jpeg" || image.type === "image/png") {
      const data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "profileImage");
      data.append("cloud_name", "dmfewrwla");

      try {
        const response = await fetch(
          "https://api.cloudinary.com/v1_1/dmfewrwla/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await response.json();

        setFormData({
          ...formData,
          image: imageData.url.toString(),
        });
        setImageLoading(false);
        console.log(imageData.url.toString());
      } catch (err) {
        console.log(err);
        setImageLoading(false);
      }
    } else {
      setImageLoading(false);
      return;
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    handleImageChange(file);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    setLoading(true);

    try {
      await handleImageChange(formData.image, formData, setFormData);

      const formatDateWithDayAdjustment = (selectedDate) =>
        formatDateToUTC(new Date(selectedDate));

      const formDataWithDate = {
        ...formData,
        dob: formatDateWithDayAdjustment(formData.dob),
      };

      const response = await axios.post(
        `${baseUrl}/api/admin/updateUser`,
        formDataWithDate,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: "User details updated.",
          description:
            "The user's details have been successfully updated. Kindly refresh the page to see the update.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      } else {
        toast({
          title: "Update failed.",
          description: "There was an error updating the user's details.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      toast({
        title: "Update failed.",
        description: "There was an error updating the user's details.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  const formatDateToUTC = (selectedDate) => {
    if (!selectedDate) return "";

    if (isNaN(new Date(selectedDate))) {
      console.error("Invalid date:", selectedDate);
      return "";
    }

    const adjustedDate = new Date(selectedDate);
    adjustedDate.setDate(adjustedDate.getDate());

    return adjustedDate.toISOString().split("T")[0];
  };

  const handlePasswordChange = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/changePassword`,
        passwordData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: "Password changed.",
          description: "Your password has been successfully changed.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setPasswordModalOpen(false);
      } else {
        toast({
          title: "Password change failed.",
          description: "There was an error changing your password.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error changing password:", error);
      toast({
        title: "Password change failed.",
        description: "There was an error changing your password.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        style={{ zIndex: 1400 }}
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white" maxH="500px" overflow="auto">
          <ModalHeader>Edit Profile Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="firstName" mb={3}>
              <FormLabel>First Name</FormLabel>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="lastName" mb={3}>
              <FormLabel>Last Name</FormLabel>
              <Input
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="phoneNumber" mb={3}>
              <FormLabel>Phone Number</FormLabel>
              <Input
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="email" mb={3}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="gender" mb={3}>
              <FormLabel>Gender</FormLabel>
              <Input
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="dob" mb={3}>
              <FormLabel>Date of Birth</FormLabel>
              <Input
                name="dob"
                type="date"
                value={formData.dob}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="address" mb={3}>
              <FormLabel>Address</FormLabel>
              <Input
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="kinName" mb={3}>
              <FormLabel>Next of Kin Name</FormLabel>
              <Input
                name="kinName"
                value={formData.kinName}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="kinNumber" mb={3}>
              <FormLabel>Next of Kin Number</FormLabel>
              <Input
                name="kinNumber"
                value={formData.kinNumber}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="relationship" mb={3}>
              <FormLabel>Relationship</FormLabel>
              <Input
                name="relationship"
                value={formData.relationship}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="language" mb={3}>
              <FormLabel>Language</FormLabel>
              <Input
                name="language"
                value={formData.language}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="image" mb={3}>
              <FormLabel>Profile Image</FormLabel>
              <Input type="file" accept="image/*" onChange={handleFileChange} />
              {imageLoading && <Spinner size="sm" mt={2} />}
            </FormControl>
            <FormControl id="dob" mb={3}>
              <FormLabel>Start date</FormLabel>
              <Input
                name="startDate"
                type="date"
                value={formData.startDate}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl id="dob" mb={3}>
              <FormLabel>End date</FormLabel>
              <Input
                name="endDate"
                type="date"
                value={formData.endDate}
                onChange={handleChange}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="red"
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={handleSubmit}
              isLoading={loading}
            >
              {loading ? <Spinner size="sm" /> : "Update"}
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={() => setPasswordModalOpen(true)}
            >
              Change Password
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Password Change Modal */}
      <Modal
        size="sm"
        isOpen={isPasswordModalOpen}
        onClose={() => setPasswordModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="oldPassword" mb={3}>
              <FormLabel>Old Password</FormLabel>
              <InputGroup>
                <Input
                  type={showOldPassword ? "text" : "password"}
                  name="oldPassword"
                  value={passwordData.oldPassword}
                  onChange={(e) =>
                    setPasswordData({
                      ...passwordData,
                      oldPassword: e.target.value,
                    })
                  }
                />
                <InputRightElement
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  cursor="pointer"
                >
                  {showOldPassword ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="newPassword" mb={3}>
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <Input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={(e) =>
                    setPasswordData({
                      ...passwordData,
                      newPassword: e.target.value,
                    })
                  }
                />
                <InputRightElement
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  cursor="pointer"
                >
                  {showNewPassword ? (
                    <AiOutlineEyeInvisible />
                  ) : (
                    <AiOutlineEye />
                  )}
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="red"
              mr={3}
              onClick={() => setPasswordModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={handlePasswordChange}
              isLoading={loading}
            >
              {loading ? <Spinner size="sm" /> : "Change Password"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditCustomerModal;
