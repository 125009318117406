import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Flex,
  useToast,
  FormControl,
  Input,
  FormLabel,
  Spinner,
} from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";

const RematchMedicModal = ({
  isOpen,
  onClose,
  appointmentId,
  appointmentActualEndDate,
  appointmentActualStartDate,
}) => {
  const [medicsData, setMedicsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMedicId, setLoadingMedicId] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const toast = useToast();

  useEffect(() => {
    const fetchMedics = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found, redirecting to login.");
        window.location.href = "/login";
        return;
      }

      try {
        const response = await axios.get(
          `${baseUrl}/api/admin/pending-medic-appointments`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMedicsData(response.data.data.content);
      } catch (error) {
        console.error("Error fetching medics:", error);
        toast({
          title: "Error fetching medics",
          description:
            "There was an error fetching the available medics for rematch.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchMedics();
  }, [toast]);

  const handleRematch = async (medicId, medicRequestId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }

    setLoadingMedicId(medicId);
    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/rematch-medic`,
        {
          appointmentId,
          medicRequestId,
          medicId,
          appointmentActualEndDate: endDate,
          appointmentActualStartDate: startDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast({
          title: "Medic Rematched",
          description:
            "The medic has been successfully rematched. Refresh the page to see the update.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      } else {
        toast({
          title: "Rematch Failed",
          description:
            "There was an issue rematching the medic. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error rematching medic:", error);
      toast({
        title: "Rematch Failed",
        description: "An error occurred while trying to rematch the medic.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setLoadingMedicId(null);
    }
  };

  return (
    <Modal
      style={{ zIndex: 1400 }}
      size="4xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent bg="#4B4B4B" color="white">
        <ModalHeader fontSize="md">Rematch Appointment's Medic</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4} color="yellow.500">
            Confirm the start date and end date of the medic's service.
          </Text>
          <Flex maxWidth="300px" color="white" mb={4} alignItems="center">
            <FormControl mb={4}>
              <FormLabel>Start Date</FormLabel>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormControl>
            <FormControl ml="5px" mb={4}>
              <FormLabel>End Date</FormLabel>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormControl>
          </Flex>
          {loading ? (
            <Flex justifyContent="center" alignItems="center">
              <Spinner size="lg" />
              <Text ml={3}>Loading available medics...</Text>
            </Flex>
          ) : medicsData.length > 0 ? (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color="#A210C6">Avatar</Th>
                  <Th color="#A210C6">Full Name</Th>
                  <Th color="#A210C6">Specialization</Th>
                  <Th color="#A210C6">Current Location</Th>
                  <Th color="#A210C6">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {medicsData.map((medic) => (
                  <Tr key={medic.medicAppointment.id}>
                    <Td>
                      <Avatar
                        name={medic.medicAppointment.fullName}
                        src={medic.medicAppointment.image}
                        size="sm"
                      />
                    </Td>
                    <Td>{medic.medicAppointment.fullName}</Td>
                    <Td>{medic.medicAppointment.specialization}</Td>
                    <Td>{medic.medicAppointment.currentLocation || "N/A"}</Td>
                    <Td>
                      <Button
                        colorScheme="yellow"
                        color="#4B4B4B"
                        onClick={() =>
                          handleRematch(
                            medic.medicAppointment.medicId,
                            medic.medicAppointment.id
                          )
                        }
                        isLoading={
                          loadingMedicId === medic.medicAppointment.medicId
                        }
                        loadingText="Rematching..."
                      >
                        Rematch
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text>No available medics for rematch.</Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" colorScheme="red" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RematchMedicModal;
