import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  ChakraProvider,
  Flex,
  Image,
  Text,
  extendTheme,
} from "@chakra-ui/react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import "../../styles/pages/LandingPage.css";
import TermsModal from "../sections/TermsModal";
import RegistrationForm from "../sections/RegistrationForm";
import logo from "../../assets/Logo.svg";
import { baseUrl, loginBaseUrl } from "../../apiCalls/config";
import VerificationMethodModal from "../sections/VerificationMethodModal ";

const customTheme = extendTheme({
  components: {
    Link: {
      baseStyle: { _focus: { boxShadow: "none" } },
    },
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Gill Sans MT, sans-serif",
  },
});

const CustomerRegistration = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    gender: "",
    dob: "",
    address: "",
    kinName: "",
    kinNumber: "",
    language: "English",
    relationship: "Self",
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false); 
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber" && value.startsWith("0")) {
      return;
    }
    setFormData({ ...formData, [name]: value });
    validateInput(name, value);
  };

  const handleDobChange = (e) => {
    const dateValue = e.target.value;
    setFormData({ ...formData, dob: dateValue });
    validateInput("dob", dateValue);
  };

  const handleClick = () => setShow(!show);

  const handleTermsChange = (e) => {
    setAgreeToTerms(e.target.checked);
    setIsTermsOpen(true);
  };

  const closeTermsModal = () => setIsTermsOpen(false);

  const getValidNigerianPhoneNumber = (phoneNumber) => {
    const pattern = /^(\d{10})$/;
    if (pattern.test(phoneNumber)) {
      return "0" + phoneNumber;
    }
    return null;
  };

  const validatePassword = (password) => {
    const pattern = /^(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{6,}$/;
    return pattern.test(password);
  };

  const validateInput = (name, value) => {
    let errors = { ...validationErrors };
    switch (name) {
      case "firstName":
      case "lastName":
        if (!value) {
          errors[name] = `${
            name === "firstName" ? "First" : "Last"
          } name is required.`;
        } else {
          delete errors[name];
        }
        break;
      case "email":
        if (!value) {
          errors[name] = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errors[name] = "Email is invalid.";
        } else {
          delete errors[name];
        }
        break;
      case "phoneNumber":
        if (!getValidNigerianPhoneNumber(value)) {
          errors[name] =
            "Please enter a valid Nigerian phone number, without the first zero";
        } else {
          delete errors[name];
        }
        break;
      case "password":
        if (!validatePassword(value)) {
          errors[name] =
            "Password must be at least 6 characters long and include letters, special characters, and numbers.";
        } else {
          delete errors[name];
        }
        break;
      case "confirmPassword":
        if (value !== formData.password) {
          errors[name] = "Passwords do not match.";
        } else {
          delete errors[name];
        }
        break;
      case "gender":
        if (!["Male", "Female"].includes(value)) {
          errors[name] = "Please select a valid gender.";
        } else {
          delete errors[name];
        }
        break;
      case "dob":
        const today = new Date();
        const minDate = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate()
        );
        if (new Date(value) > minDate) {
          errors[name] = "You must be at least 18 years old.";
        } else {
          delete errors[name];
        }
        break;
      default:
        break;
    }
    setValidationErrors(errors);
  };

  const sendOtp = async () => {
    try {
      const number = localStorage.getItem("phoneNumber");
      await axios.post(
        `${loginBaseUrl}/api/v1/sms/verify-number`,
        { phoneNumber: number },
        { headers: { "Content-Type": "application/json" } }
      );
      toast.success("OTP has been sent to your phone number for verification.");
    } catch (error) {
      toast.error("Unable to resend OTP at this time. Please try again later.");
    }
  };
  
  const sendEmailOtp = async (email) => {
    try {
      await axios.post(
        `${baseUrl}/angel/send-email-otp?email=${encodeURIComponent(email)}`,
        {}, 
        { headers: { "Content-Type": "application/json" } }
      );
      toast.success("OTP has been sent to your email for verification.");
      setTimeout(() => {
        navigate("/verify-email");
      }, 5000);
    } catch (error) {
      toast.error("Unable to send email OTP. Please try again.");
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    validateInput("firstName", formData.firstName);
    validateInput("lastName", formData.lastName);
    validateInput("email", formData.email);
    validateInput("phoneNumber", formData.phoneNumber);
    validateInput("password", formData.password);
    validateInput("confirmPassword", formData.confirmPassword);
    validateInput("gender", formData.gender);
    validateInput("dob", formData.dob);
  
    if (Object.keys(validationErrors).length > 0) {
      toast.warning("Please ensure all the fields has the right input");
      return;
    }
  
    if (!agreeToTerms) {
      toast.warning("You have to accept our terms and conditions to continue");
      return;
    }
  
    const validPhoneNumber = getValidNigerianPhoneNumber(formData.phoneNumber);
    if (!validPhoneNumber) {
      toast.warning(
        "Please enter a valid Nigerian phone number, without the first zero"
      );
      return;
    }
  
    if (!validatePassword(formData.password)) {
      toast.warning(
        "Password must be at least 6 characters long and include letters, special characters, and numbers"
      );
      return;
    }
  
    if (formData.password !== formData.confirmPassword) {
      toast.warning("Passwords do not match");
      return;
    }
  
    setLoading(true);
    localStorage.setItem("phoneNumber", validPhoneNumber);
    localStorage.setItem("email", formData.email.toLowerCase());
    try {
      const updatedFormData = {
        ...formData,
        phoneNumber: validPhoneNumber,
        email: formData.email.toLowerCase(),
      };
  
      const response = await axios.post(
        `${baseUrl}/angel/join`,
        updatedFormData,
        { headers: { "Content-Type": "application/json" } }
      );
  
      if (response.data.success) {
        setIsVerificationModalOpen(true);
      } else {
        setLoading(false);
        toast.error(response.data.message || "Registration failed");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message); 
      } else {
        toast.error("Registration failed. Please try again.");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };  

  // Handle phone verification
  const handlePhoneVerification = async () => {
    await sendOtp();
    setIsVerificationModalOpen(false);
    setTimeout(() => {
      navigate("/verify-number");
    }, 6000);
  };

  // Handle email verification
  const handleEmailVerification = async () => {
    await sendEmailOtp(formData.email);
    setIsVerificationModalOpen(false);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ChakraProvider theme={customTheme}>
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Flex
        overflowX={{ base: "auto", md: "hidden" }}
        overflowY={{ base: "hidden", md: "auto" }}
        align="center"
        justify="center"
        minHeight="100vh"
      >
        <Box
          mb={{ base: "50px", md: "100px" }}
          mt={{ base: "50px", md: "100px" }}
          width={{ base: "90%", sm: "500px" }}
          h={{ base: "auto", md: "auto" }}
          p="6"
          bg="white"
          boxShadow="lg"
        >
          <a href="/">
            <Image
              justifySelf="center"
              src={logo}
              alt="Logo"
              mx="auto"
              h={{ base: "60px", md: "100px" }}
              w={{ base: "200px", md: "350px" }}
            />
          </a>
          <Text
            fontFamily="header"
            fontSize="2xl"
            color="#A210C6"
            mb="4"
            textAlign="center"
          >
            Create your account
          </Text>
          <RegistrationForm
            formData={formData}
            handleInputChange={handleInputChange}
            handleDobChange={handleDobChange}
            handleClick={handleClick}
            show={show}
            handleSubmit={handleSubmit}
            validationErrors={validationErrors}
            agreeToTerms={agreeToTerms}
            handleTermsChange={handleTermsChange}
            loading={loading}
          />
          <Text fontSize="16px" fontFamily="body" mt="15px">
            Already have an account?{" "}
            <Link
              to="/login"
              style={{
                fontStyle: "italic",
                color: "#A210C6",
                textDecoration: "none",
              }}
            >
              Login
            </Link>
          </Text>
        </Box>
      </Flex>

      <VerificationMethodModal
        isOpen={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
        handlePhoneVerification={handlePhoneVerification}
        handleEmailVerification={handleEmailVerification}
      />

      <TermsModal isOpen={isTermsOpen} onClose={closeTermsModal} />
    </ChakraProvider>
  );
};

export default CustomerRegistration;
